export function tabs() {
    var tabs = document.querySelectorAll('.tab-container__button');
    var tabsAndContent = document.querySelectorAll('.tab-container__button, .tab-container__item');

    tabs.forEach(function(el, index){
        el.addEventListener('click', function(e){
            e.preventDefault();
            _closeTabs();
            _openTab(el);
        });
    });

    if(tabs.length && window.location.hash !== ""){
        var thisHash = window.location.hash;
        thisHash = thisHash.replace('#','');
        var hashTabs = document.querySelectorAll('[data-id="'+thisHash+'"]');

        if(hashTabs.length){
            _closeTabs();

            hashTabs.forEach(function(el, index){
                _openTab(el);
            });

            if(window.matchMedia('(min-width: 992px)').matches){
                $('html, body').animate({
                  scrollTop: $(hashTabs[0]).offset().top - $('.site-header').outerHeight()
                }, 600);
            }else{
                $('html, body').animate({
                  scrollTop: $(hashTabs[2]).offset().top - $('.site-header').outerHeight()
                }, 600);
            }
        }
    }

    function _closeTabs(){
        tabsAndContent.forEach(function(el2, index2){
            el2.classList.remove('active');
        });
    }

    function _openTab(el){
        var thisID = el.dataset.id;

        el.classList.add('active');

        var contentDivs = document.querySelectorAll('[data-id="'+thisID+'"]');
        // one for desktop, one for mobile
        contentDivs.forEach(function(el2, index){
            el2.classList.add('active');
        });
    }
}

