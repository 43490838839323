export function hamburger() {
    $('.site-header__hamburger, .sidebar-nav__close').on('click', function(){
        $('body').toggleClass('menu-open');
    });
}

export function sidebarNav() {
    var menuItemsWithChildren = document.querySelectorAll('.sidebar-nav li.menu-item-has-children > a > .nav-forward, .sidebar-nav li.menu-item-has-children > a > .nav-back');
    var menuItemsWithTransition = document.querySelectorAll('.sidebar-nav .nav-box > ul > li');

    menuItemsWithChildren.forEach(function(el, index){
        el.addEventListener('click', function(e){
            e.preventDefault();

            el.parentNode.parentNode.classList.toggle('child-active');
            el.parentNode.parentNode.parentNode.classList.add('transitioning');
            el.closest('.nav-box').classList.toggle('level-1-active');
        });
    });

    // add/remove class when transitions end
    menuItemsWithTransition.forEach(function(el, index){
        _transitionEndEvents(el, function(){
            el.parentNode.classList.remove('transitioning');
        });
    });

    document.body.addEventListener('click', function(e){
        var target = e.target;
        
        if(document.body.classList.contains('menu-open')){
            if(!target.classList.contains('sidebar-nav') &&
                !target.classList.contains('site-header__hamburger') && 
                !target.closest('.sidebar-nav')
                ){
                document.body.classList.remove('menu-open');
            }
        }
    });

    // set height of nav box
    // var navBox = document.querySelector('.sidebar-nav .nav-box');
    // navBox.style.height = window.innerHeight - navBox.getBoundingClientRect().top + 'px';
    // window.addEventListener('resize', function(){
    //     navBox.style.height = window.innerHeight - navBox.getBoundingClientRect().top + 'px';
    // });

    function _transitionEndEvents(el, func){
        var events = ['transitionend', 'oTransitionEnd','transitionend','webkitTransitionEnd'];
        for(var i = 0; i < events.length; i++){
            el.addEventListener(events[i], func);
        }
    }

}

export function stickyHeader() {
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 20) {
            $('.site-header').addClass('scrolled');
        } else {
            $('.site-header').removeClass('scrolled');
        }
    });
}