export function accordion() {
    // Accordion for FAQs (jQuery)
    $('.accordion .accordion__title.active').next().slideDown()

    $('.accordion').on('click', '.accordion__title', function () {
        $('.accordion__content').slideUp();

        if (!$(this).hasClass('active')) {
            // remove active class from any others that might be active
            $('.accordion .accordion__title.active').removeClass('active');
            $(this).addClass('active');
            $(this).next().slideDown();
        } else {
            $(this).removeClass('active');
        }
    });
}

