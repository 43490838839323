import './vendor/contentloaded.min.js'
import './vendor/jquery.viewportchecker.min.js';
import './vendor/micromodal.min.js';

import {accordion} from './components/accordion';
import {tabs} from './components/tabs';
import {swiperDesktop, swiperMobile} from './components/swiper';
import {hamburger,  stickyHeader} from './components/header';
import { viewAnimation, smoothScroll } from "./util/animation";
import {outputMonth, countDown} from './util/dates';
import {popups} from './components/popup';
import {phoneConcat, readMore} from './util/text';
import {cf7Redirect} from './util/window';
import {sidebarNav} from './components/header';

// normal JS
window.contentLoaded(window, function (e) {
    // var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // if(isSafari){
    //     document.body.classList.add('safari');
    // }

    tabs();
    outputMonth();
    swiperDesktop();
    swiperMobile();
    sidebarNav();
    popups();
});

// jquery reliant functions
jQuery(document).ready(function($){
    accordion();
    stickyHeader();
    hamburger();
    viewAnimation();
    smoothScroll();
    phoneConcat();
    readMore();
});

