import Swiper, {Navigation, Pagination, Autoplay} from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay]);

// Modifies the options to include those set within the data-* attributes.
function getSwiperOptionAttributes (options, swiperElm) {

  // These options can be set via the attributes of the swiper element
  const setable = {
    'loop': 'loop',
    'spaceBetween': 'spacebetween',
    'slidesPerView': 'slidesperview',
    'autoplay': 'autoplay'
  }

  var optionsAttributes = swiperElm.dataset;

  for ( var key in setable ) {
    var value = setable[key];
    if (optionsAttributes[value]) {
      options[key] = isNaN(optionsAttributes[value]) ? (optionsAttributes[key]) : parseInt(optionsAttributes[value]);
    }

    // Breakpoint attributes if any
    if( optionsAttributes['768_' + value] ) {
      options.breakpoints[768][key] = isNaN(optionsAttributes['768_' + value]) ? (optionsAttributes['768_' + value]) : parseInt(optionsAttributes['768_' + value])
    }
    if( optionsAttributes['992_' + value] ) {
      options.breakpoints[992][key] = isNaN(optionsAttributes['992_' + value]) ? (optionsAttributes['992_' + value]) : parseInt(optionsAttributes['992_' + value])
    }

  }

  return options
}

export function swiperDesktop () {
  // get all sliders, we need to loop them due to different settings + nav
  var swipers = document.querySelectorAll(
    '.swiper-container:not(.control):not(.mobile)'
  )
  swipers.forEach(function (el, index) {
    var closestSection = el.closest('section')
    var controls = closestSection.querySelector('.control')

    // Slider default settings
    var options = {
      speed: 600,
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        }
      },
      navigation: {},
      thumbs: {}
    }

    // Slider attribute overrides
    options = getSwiperOptionAttributes(options, el)

    // init nav
    options.navigation = {
      nextEl: closestSection.querySelector('.swiper-next'),
      prevEl: closestSection.querySelector('.swiper-prev')
    }

    // maybe add gallery controls to the slider
    if (controls) {
      var controlOptions = {
        speed: 600,
        loop: true,
        slidesPerView: 4
      }

      controlOptions = getSwiperOptionAttributes(controlOptions, controls)

      options.thumbs.swiper = new Swiper(controls, controlOptions)
    }

    // init slider
    new Swiper(el, options)
  })
}

export function swiperMobile () {
  // mobile sliders, like logo rows etc
  // need to loop in order to get the slide count
  var mobileSwipers = document.querySelectorAll('.swiper-container.mobile')
  mobileSwipers.forEach(function (el, index) {
    var closestSection = el.closest('section')
    var slideCount = el.querySelectorAll('.swiper-slide').length

    var options = {
      speed: 600,
      slidesPerView: 3,
      watchOverflow: true,
      loop: true,
      simulateTouch: false,
      observer: true,
      loop: false,
      draggable: true,
      observeParents: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      breakpoints: {
        576: {
          slidesPerView: 3
        },
        768: {
          slidesPerView: 4
        },
        992: {
          slidesPerView: slideCount
        }
      }
    }

    // init nav
    options.navigation = {
      nextEl: closestSection.querySelector('.swiper-next'),
      prevEl: closestSection.querySelector('.swiper-prev')
    }

    options = getSwiperOptionAttributes(options, el)

    // init slider
    new Swiper(el, options)
  })
}
