export function viewAnimation () {
  // Components loading animations
  $('.view-animation').viewportChecker({
    classToAdd: 'animated',
    offset: 20
  })
}


export function smoothScroll() {
  $(document).on('click', 'a[href*="#"]:not([href*="#popup-"]):not([href="#"])', function (event) {
      var target = $(this).attr('href');

      if(target == '#pagetitle-next'){
          target = '.section-pagetitle + section';
      }

      // probably a tab link
      if(target.indexOf('http') > -1){
          var realTarget = target.split('#')[1];

          if($('[data-id="'+realTarget+'"]:visible').length){
            target = '[data-id="'+realTarget+'"]';
            $('[data-id="'+realTarget+'"]:visible').click();
          }
      }

      if ($(target).length) {
          event.preventDefault();

          $('html, body').animate({
              scrollTop: $(target).offset().top - $('.site-header').outerHeight()
          }, 600);
      }
  });
}
